/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  users: [],
  roles: [],
  RoleName: "",
  UserSearchObj: {
    FullName: "",
    Email: "",
    PhoneNumber: "",
    // UserType: "",
    // IsActive:null,
  }
};
