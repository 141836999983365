/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";
import { Model } from "vue-property-decorator";

export default {
    addItem({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/User/AddUser", item)
                .then(response => {
                    commit(
                        "ADD_ITEM",
                        Object.assign(item, { Id: response.data.Data.Id })
                    );
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fetchDataListItems({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/User/GetAllUsers")
                .then(response => {
                    commit("SET_USERS", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    SendCode(context, email) {

        return new Promise((resolve, reject) => {
            axios.post("/SendVerifyCodeForEmail?email=" + email + '&isResetPassword=' + true)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    UserSearch({ commit }, model) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/User/UserSearch", model)
                .then(response => {
                    debugger
                    commit("SET_USERS", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllRoles({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/GetAllRoles")
                .then(response => {
                    commit("SET_ROLES", response.data.Data);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdateUser(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/User/UpdateUser", item)
                .then(response => {
                    // commit('UPDATE_USER', response.data.Data)
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },


    ResetPassword(context, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("/ResetPassword?email=" + item.email + "&code=" + item.code + "&newPassword=" + item.password

                )
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    removeUser({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .post("/API/User/DeleteUser?userId=" + id)
                .then(response => {
                    commit("REMOVE_ITEM", id);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetUserById(context, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/User/GetUserById?userId=" + id)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllSpecialties(context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Specialty/GetAllSpecialties")
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllPatients(context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Patient/GetAllPatients")
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    GetAllCountries(context) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/Country/GetAllCountries")
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    DeleteUserSpcialty({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/User/DeleteUserSpcialty?userSpcialtyId=" + id)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    DeleteUserDoctor({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/User/DeleteUserDoctor?useDoctorId=" + id)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    DeleteUserHospital({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/User/DeleteUserHospital?userHospitalId=" + id)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    DeleteUserPatient({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/User/DeleteUserPatient?usePatientId=" + id)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },


    DeleteUserPatientLocation({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get("/API/User/DeleteUserPatientLocation?userPatientLocationId=" + id)
                .then(response => {

                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    UpdatePassword(context, item) {
        return new Promise((resolve, reject) => {
            axios
                .post("/ForceResetPassword", item)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

};