/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.users.unshift(item);
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },

  UPDATE_USER(state, product) {
    const productIndex = state.users.findIndex(p => p.id == product.id);
    Object.assign(state.users[productIndex], product);
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.users.findIndex(p => p.Id == itemId);
    state.users.splice(ItemIndex, 1);
  },
  CHANGE_ROLENAME(state, RoleName) {
    state.RoleName = RoleName;
  },
  SET_UserSearchObj(state, UserSearchObj) {
    debugger
    state.UserSearchObj = UserSearchObj;
  }
};
